.static-view {
	&.mit-fahrrad {
		.inner-banner {
			background-image: url(RESOURCE/img/mit-fahrrad-banner.jpg);
			height: 480px;
		}
	}

	&.paare-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/Paare-banner.jpg);
			height: 480px;
		}
	}

	&.mit-hund-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/hund-banner.jpg);
			height: 480px;
		}
	}

	&.workation-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/Workation-banner.jpg);
			height: 480px;
		}
	}

	&.familien-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/familien-banner.jpg);
			height: 480px;
		}
	}

	&.wassersportler-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/Wassersportler-banner.jpg);
			height: 480px;
		}
	}
}