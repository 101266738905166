.unit-request-form-v1 {
	.bg-banner {
		background-image: url(RESOURCE/img/demo3-parallax.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}



	.checkbox label {
		vertical-align: inherit;
	}

	.unit-pic {
		img {
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;
			height: 100%;
			width: 100%;
			opacity: 0.5
		}
	}
}

.unit-contact-form-v1 {
	.required::after {
		content: "*";
		vertical-align: super;
		font-size: var(--font-size-sm);
	}
}