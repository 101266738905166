.regionen-v1 {
	margin-top: var(--page-margin-top);
}

.inner-banner-enkhuizen {
	background-image: url(RESOURCE/img/region_enkhuizen.jpg);
}

.inner-banner-olburgen {
	background-image: url(RESOURCE/img/region_olburgen.jpg);
	background-position: top;
}

.inner-banner-breskens {
	background-image: url(RESOURCE/img/region_breskens.jpg);
}


.inner-banner-ruzici {
	background-image: url(RESOURCE/img/region_ruzici.jpg);
	background-position: top;
}