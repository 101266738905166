.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);
		font-size: 15px;

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		.first-col {
			position: relative;

			.email {
				position: absolute;
				bottom: 14px;
				left: 15px;
				white-space: nowrap;
			}
		}

		.container-xl {
			max-width: 1200px;
		}

		position: relative;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 110px;
			height: auto;
			margin-top: 0;

			display: block;
		}

		.v-office-logo {

			@media(min-width: 993px) {
				text-align: right;
			}

			@media(max-width: 993px) {
				text-align: left;
			}
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		/*	i {
			color: #ffc371;
		}*/

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa {
				color: var(--color-white);
				font-size: 2.3rem;
				padding-left: 10px;

			}
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		a {
			color: var(--footer-copyright-font-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		.v-office-logo {
			float: left;
			padding-right: 20px;

			img {
				width: 50px;
			}
		}
	}

}